var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-wrapper"},[_c('v-app-bar',{staticClass:"box-shadow-theme main-app-header",class:{
        'box-shadow__ischat': _vm.checkCurrentTabIsChat
      },attrs:{"absolule":"","dense":"","color":_vm.checkDarkThemeForRoute ? '' : 'white',"height":"60","dark":_vm.checkDarkThemeForRoute}},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(/*Menu btn*/)+" "),(_vm.checkPermit('kw_show_menu'))?_c('v-app-bar-nav-icon',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_vm._v(" "+_vm._s(/*Menu btn*/)+" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"ml-3 d-flex align-center"},[_c('router-link',{attrs:{"to":{name: 'Welcome' },"custom":""}},[_c('v-img',{attrs:{"height":"21","width":"36","src":require("@/assets/logo-kwizbot.svg")}})],1),_c('v-divider',{staticClass:"ml-5 mr-5",attrs:{"vertical":"","height":"28"}}),_vm._v(" "+_vm._s(/*Bot name*/)+" "),(_vm.checkPermit('show_kw_active_bot_title'))?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.active_bot_title))]):_vm._e()],1):_vm._e()],1),_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.renderExtension
					&& _vm.extension.show
					&& _vm.checkCurrentPageWelcome
				),expression:"\n\t\t\t\t\trenderExtension\n\t\t\t\t\t&& extension.show\n\t\t\t\t\t&& checkCurrentPageWelcome\n\t\t\t\t"}],staticClass:"menu-tab-links__wrapper",attrs:{"align-center":""}},[(_vm.scrollLeftBtn)?_c('v-btn',{attrs:{"icon":"","text":"","plain":"","small":"","ripple":false},on:{"click":function($event){return _vm.scrollMenuLeft()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll.x",value:(true),expression:"true",modifiers:{"x":true}}],ref:"scrolledTabMenu",staticClass:"menu-tab-links",class:{
            'menu-tab-links__dark': _vm.checkDarkThemeForRoute,
          },on:{"dragscrollstart":_vm.dragStart,"dragscrollend":_vm.dragEnd,"!click":function($event){return _vm.dragClick($event)}}},[_vm._l((_vm.extension.tabs_items),function(item){return [(item.show && _vm.showMenuTabOnlyAdmin(item))?_c('router-link',{key:item.type,attrs:{"to":_vm.routerLinkTab(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
          var href = ref.href;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('v-btn',{class:{ 'active_tab_menu' : isExactActive || _vm.checkActiveTab(item) },attrs:{"text":"","plain":"","elevation":"0","tile":"","href":href},on:{"click":navigate}},[(item.type === 'icon')?[_c('v-icon',{attrs:{"color":isExactActive ? 'primary' : ''}},[_vm._v(_vm._s(item.tab))])]:[_vm._v(" "+_vm._s(item.tab)+" ")]],2)]}}],null,true)}):_vm._e()]})],2),(_vm.scrollRightBtn)?_c('v-btn',{attrs:{"icon":"","text":"","plain":"","small":"","ripple":false},on:{"click":function($event){return _vm.scrollMenuRight()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_vm._v(" "+_vm._s(/*Menu Account Right*/)+" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-menu',{attrs:{"min-width":"200px","transition":"slide-y-transition","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary","ripple":false,"depressed":"","id":"no-background-hover","min-width":"90","title":_vm.userInfo.email}},on),[_c('v-avatar',{staticClass:"mr-1",attrs:{"height":"40","width":"40","min-width":"40","color":"primaryLight"}},[_c('v-img',{attrs:{"height":"40","width":"40","src":_vm.userInfo.avatar_img}})],1),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2996952883)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"menu-item"},[_c('appLangSetting',{attrs:{"mode":"small_btn"}})],1)],1),_c('v-list-item',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"depressed":"","block":"","ripple":false,"color":"primary","width":"100%"},on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$iconlogout")]),_c('span',[_vm._v(_vm._s(_vm.$t('header.logout')))])],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "+_vm._s(/*Menu Account Right*/)+" ")],1),_vm._v(" "+_vm._s(/*Menu list*/)+" "),_c('v-navigation-drawer',{staticClass:"main-menu-drawer",attrs:{"absolute":_vm.$vuetify.breakpoint.mdAndUp,"temporary":"","fixed":_vm.$vuetify.breakpoint.smAndDown,"dark":_vm.checkDarkThemeForRoute},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"menu-footer"},[_vm._v(" "+_vm._s(/*mobile footer*/)+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$iconlogout")]),_vm._v(" "+_vm._s(_vm.$t('header.logout'))+" ")],1),_c('appLangSetting',{attrs:{"mode":"small"}})],1)],1):_vm._e(),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.version),expression:"version"}],attrs:{"right":"","top":"","max-width":"320","content-class":"menu--version","light":"","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"menu--version__chip mt-2",attrs:{"outlined":"","color":"primary","link":""}},'v-chip',attrs,false),on),[_vm._v(" v"+_vm._s(_vm.version)+" ")])]}}]),model:{value:(_vm.showVersionTooltip),callback:function ($$v) {_vm.showVersionTooltip=$$v},expression:"showVersionTooltip"}},[_c('div',[(_vm.versionUpdated)?_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('version.header_new_version')))]):_c('h4',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('version.header_hello')))]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.$t('version.text_descr'))+" "),_c('a',{attrs:{"href":_vm.returnNotionLink(),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('version.notion_text'))+" ")])])]),(false)?_c('v-btn',{staticClass:"mt-3 mx-auto",attrs:{"x-small":"","color":"success","elevation":"0"}},[_vm._v(_vm._s(_vm.$t('version.btn_telegram_text')))]):_vm._e()],1)])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_vm._v(" "+_vm._s(/*mobile header*/)+" "),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"\n\t\t\t\t\tmenu-mob-header\n\t\t\t\t\td-flex\n\t\t\t\t\tjustify-space-between\n\t\t\t\t\talign-center\n\t\t\t\t",class:{
          'menu-mob-header__dark': _vm.checkDarkThemeForRoute
        }},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":"","tile":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-img',{attrs:{"max-height":"40","max-width":"40","src":require("@/assets/logo.png")}})],1),_c('v-divider',{staticClass:"ml-5 mr-5",attrs:{"vertical":""}}),_vm._v(" "+_vm._s(/*Bot name*/)+" "),(_vm.checkPermit('show_kw_active_bot_title'))?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.active_bot_title))]):_vm._e()],1),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e(),_vm._v(" "+_vm._s(/*mobile header*/)+" "+_vm._s(/* MENU LIST */)+" "),_c('v-list',{staticClass:"menu_list",attrs:{"subheader":""}},[_c('v-list-item-group',{attrs:{"active-class":"menu-item__active"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_vm._l((_vm.full_menu_items),function(item,i){return [(
                item.show
                && _vm.checkModuleOrTabPermit(item)
                && !_vm.checkMenuItemsModuleWithoutActiveBot(item)
                && item.module === 'OperatorPanel'
              )?_c('v-list-item',{key:i,staticClass:"py-2"},[_c('v-list-item-title',{staticClass:"menu-item",attrs:{"role":"link"},on:{"click":function($event){return _vm.routerLinkMenu(item)}}},[_c('div',[(item.icon)?_c('v-icon',{attrs:{"color":"greyBlueLight"}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.title))]),(item.module === 'OperatorPanel' && _vm.isNotRead())?_c('div',{staticClass:"notification"},[_c('v-icon',{attrs:{"small":"","color":"#5684eb"}},[_vm._v("mdi-bell")])],1):_vm._e()])],1):_vm._e()]}),_c('v-divider'),_c('v-subheader',{staticClass:"gray--text text-caption"},[_vm._v(_vm._s(_vm.$t('modules.dialog.texts.settings.subhead_menu_active_bot')))]),_c('v-select',{staticClass:"mx-4 mt-4 mb-2 flex-grow-0",attrs:{"value":_vm.bot_settings.active_id,"items":_vm.botsSelect,"label":_vm.$t('modules.dialog.texts.settings.default_bot'),"hide-details":"","filled":"","outlined":"","dense":"","background-color":_vm.checkDarkThemeForRoute ? '' : 'primaryBackground',"append-icon":"mdi-chevron-down","dark":_vm.checkDarkThemeForRoute},on:{"change":function($event){return _vm.setActiveBot($event)}}}),_vm._l((_vm.full_menu_items),function(item,i){return [(
              item.show
              && _vm.checkModuleOrTabPermit(item)
              && !_vm.checkMenuItemsModuleWithoutActiveBot(item)
              && item.module !== 'OperatorPanel'
            )?_c('v-list-item',{key:i},[_c('v-list-item-title',{staticClass:"menu-item",attrs:{"role":"link"},on:{"click":function($event){return _vm.routerLinkMenu(item)}}},[_c('div',[(item.icon)?_c('v-icon',{attrs:{"color":"greyBlueLight"}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.title))])])],1):_vm._e()]}),_c('v-divider'),_c('v-subheader',{staticClass:"gray--text text-caption"},[_vm._v(_vm._s(_vm.$t('modules.dialog.texts.settings.subhead_settings_menu')))]),_vm._l((_vm.full_menu_items),function(item,i){return [(
              item.show
              && _vm.checkModuleOrTabPermit(item)
              && _vm.checkMenuItemsModuleWithoutActiveBot(item)
            )?_c('v-list-item',{key:i},[_c('v-list-item-title',{staticClass:"menu-item",attrs:{"role":"link"},on:{"click":function($event){return _vm.routerLinkMenu(item)}}},[_c('div',[(item.icon)?_c('v-icon',{attrs:{"color":"greyBlueLight"}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.title))])])],1):_vm._e()]})],2)],1),_vm._v(" "+_vm._s(/* end MENU LIST */)+" ")],1),_vm._v(" "+_vm._s(/*Menu list*/)+" "+_vm._s(/*modules*/)+" "),(_vm.dataReady)?_c('router-view'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }